import React from "react"

import { Base, BaseProps } from "../Base/Base"
import { SentryErrorBoundary } from "./ErrorBoundary"
import { WithUserProvider } from "./UserProvider"

export const WrappedBase: React.FunctionComponent<BaseProps> = props => {
  return (
    <SentryErrorBoundary>
      <WithUserProvider>
        <Base {...props} />
      </WithUserProvider>
    </SentryErrorBoundary>
  )
}
